.CalendarDay__selected_span {
  background: #5081ff; 
  color: white;
  border: 1px double #1d5dff;
}

.CalendarDay__selected_span:hover {
  background: #1d5dff; 
  color: white;
  border: 1px double #1d5dff;
}
 
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #1d5dff;
  color: white;
  border: 1px double #1d5dff;
}
 
.CalendarDay__selected:hover {
  background: #fc5c7d;
  color: white;
}
 
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #5081ff;
  border: 1px double #1d5dff;
  color: white;
}
.DateRangePicker .DateInput_input {
  font-weight:normal;
  padding:0.375rem 0.75rem;
}
.DateRangePicker .DateInput_input__focused {
  border-bottom:0.3em solid #1d5dff;
}
.DayPicker_focusRegion {
  z-index:1001;
}
.DayPickerNavigation {
  z-index: 1003;
}
.CalendarMonthGrid{
  z-index: 1001;
}
.CalendarMonthGrid_month__hideForAnimation {
  z-index: 1000;
}
.DateRangePicker_picker {
  z-index: 1001;
}
.DayPicker_weekHeader {
  z-index: 1003;
}