.languageswitch {
    &__label {
       border: none!important;
       background: none!important;
       color: var(--light-gray)!important;
       padding: 0!important;
       display: flex;
       flex-direction: row;
       align-items: center;
        ::after {
            margin-left: 10px;
        }
    }

    &__menu {
        position: relative;
        border: none;
        border: 6px;
        top: 16px!important;
        .arrow-up {
            position: absolute;
            top: -16px;
            margin-left: auto;
            left: 43%;
        }
    }
   
}
.arrow-up {
    width: 0; 
    height: 0; 
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    
    border-bottom: 16px solid white; 
    box-shadow: 0 16px 10px -17px rgba(0, 0, 0, 0.5);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}