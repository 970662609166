// override bootstrap 4 variables

// $blue: #2196f3;
// $indigo: #536dfe;
// $purple: #9c27b0;
// $pink: #ff4081;
// $red: #f44336;
// $orange: #ff9800;
// $yellow: #ffeb3b;
// $green: #4caf50;
// $teal: #009688;
// $cyan: #00bcd4;
// $white: #fff;
$theme-colors: (
  "primary": #1d5dff,
  "secondary": #fc5c7d,
  "success": #45b649,
  "danger": #f85032,
  "warning": #ffd700,
  "info": #00c9ff,
  "dark-gray":#2e2e2e,
  "light-gray":#535353,
  "light-purple": #7B84FC
);

$enable-gradients: true;

// card
//
$card-cap-bg: #fff;


// sidebar
$cr-sidebar-width: 224px;

$grid-breakpoints: (
  xs: 0,
  sm: 641px,
  md: 768px,
  lg: 1023px,
  xl: 1199px,
  xxl: 1610px
);

$container-max-widths: (
  xs: 0,
  sm: 641px,
  md: 768px,
  lg: 1023px,
  xl: 1199px,
  xxl: 1610px
)
