.help-modal>.modal-content{
 border: none !important;
 border-radius: 0 !important;
 position: relative;
}

.help-modal>::before {
    content: ' ';
    display: block;
    position: absolute;
    width: 30%;
    height: 100%;
    opacity: 0.6;
    background-image: url('/corner_art/a13.png');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    right: 0;
    top: 40px;
  }

.help-modal>.modal-content {
    position: relative;
}