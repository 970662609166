.servicelistblock {
  background-color: rgb(230, 230, 230);
  border-radius: 0.3em;
  padding: 1em;
  margin: 0.5em;
  background-image: url("/bgpattern.jpg");
  background-size: 10%;
}
.display-block {
  display: block;
}

.display-none {
  display: none;
}
.serviceListListItem {
  margin-right: 1em;
  margin-bottom: 1em;
}
.table-row-adding-block {
  background-color: rgb(255, 255, 255);
  margin-top: 1em;
  margin-bottom: 1em
}