.upgradeblocks {
    width: 100%;
    height: 100%;
    background-image: url("/corner_art/a06.png");
    background-size: 35%;
    background-repeat: no-repeat;
    background-position: right top;
    padding-top: 100px
}

.upgradeblocks-contain {
    width: 100%;
    margin: auto auto;
}

.upgradeblocks-icon {
    font-size: 140px;
    margin: 50px 0;
}

.upgradeblocks-text {
    text-align: center;
}

.upgradeblocks-button {
    width: 150px;
    padding: 50px;
}